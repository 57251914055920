@use 'src/css/breakpoint.scss' as *;

.wrap {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  max-width: 970px;
  margin: auto;
  padding: 28px 67px;
  background: var(--light-bg-color);
  box-shadow: 0px 3px 4px var(--color-primary-alpha-10);
  border-radius: 8px;
  z-index: 5;

  @include maxMediaWidth(lg) {
    padding: 20px;
    margin: 0 15px;
  }

  @include maxMediaWidth(sm) {
    z-index: 4;
  }
}

.hide {
  display: none;
}

.container {
  display: flex;
  align-items: center;

  @include maxMediaWidth(lg) {
    display: block;
  }
}

.notifyText {
  font-size: 1.4rem;
  color: var(--light-neutral-text-color);
}

.wrapText {
  flex: 1 1 auto;
  padding-right: 40px;

  @include maxMediaWidth(lg) {
    display: block;
    margin-bottom: 20px;
    padding-right: 20px;
  }
}

.wrapButton {
  flex: 0 0 170px;

  .button {
    padding: 10px 48px;
    font: var(--font-button-md-bold);
    width: 100%;
  }
}

.link {
  font-size: 1.4rem;
}

.wrapCloseIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  padding: 20px;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  cursor: pointer;

  @include maxMediaWidth(lg) {
    top: 10px;
    margin: 0;
  }
}

.closeIcon {
  width: 18px;
  height: 18px;
}
